import { Action } from "@ngrx/store"
import { companyFaultRequests } from "src/app/models/companyFaultRequests"





export const ADD_COMPANYFAULTREQUESTS   = '[COMPANYFAULTREQUESTS] Ekle'
export const UPDATE_COMPANYFAULTREQUESTS    = '[COMPANYFAULTREQUESTS ] Güncelle'
export const ADD_LIST_COMPANYFAULTREQUESTS  = '[COMPANYFAULTREQUESTS ] Liste Ekle'
export const CLEAR_COMPANYFAULTREQUESTS    = '[COMPANYFAULTREQUESTS ] List Temizle'
export const REMOVE_COMPANYFAULTREQUESTS    = '[COMPANYFAULTREQUESTS ] Sil'


export class AddCompanyFaultRequests  implements Action {
    readonly type = ADD_COMPANYFAULTREQUESTS 

    constructor(public payload: companyFaultRequests) {}
}

export class UpdateCompanyFaultRequests implements Action {
    readonly type = UPDATE_COMPANYFAULTREQUESTS 

    constructor(public payload: companyFaultRequests) {}
}

export class AddListCompanyFaultRequests implements Action {
    readonly type = ADD_LIST_COMPANYFAULTREQUESTS 

    constructor(public payload: companyFaultRequests[]) {}
}

export class ClearCompanyFaultRequests implements Action {
    readonly type = CLEAR_COMPANYFAULTREQUESTS 
    constructor() { }
}

export class RemoveCompanyFaultRequests implements Action {
    readonly type = REMOVE_COMPANYFAULTREQUESTS 

    constructor(public payload: number) {}
}

export type Actions = AddCompanyFaultRequests | UpdateCompanyFaultRequests |  AddListCompanyFaultRequests |  ClearCompanyFaultRequests | RemoveCompanyFaultRequests