import { Action } from "@ngrx/store"
import { companyFaultRequests } from "src/app/models/companyFaultRequests"


export const ADD_COMPANYFAULTTYPES = '[COMPANYFAULTTYPES] Ekle'
export const UPDATE_COMPANYFAULTTYPES = '[COMPANYFAULTTYPES] Güncelle'
export const ADD_LIST_COMPANYFAULTTYPES = '[COMPANYFAULTTYPES] Liste Ekle'
export const CLEAR_COMPANYFAULTTYPES = '[COMPANYFAULTTYPES] List Temizle'
export const REMOVE_COMPANYFAULTTYPES = '[COMPANYFAULTTYPES] Sil'


export class AddCompanyFaultTypes implements Action {
    readonly type = ADD_COMPANYFAULTTYPES

    constructor(public payload: companyFaultRequests) { }
}

export class UpdateCompanyFaultTypes implements Action {
    readonly type = UPDATE_COMPANYFAULTTYPES

    constructor(public payload: companyFaultRequests) { }
}

export class AddListCompanyFaultTypes implements Action {
    readonly type = ADD_LIST_COMPANYFAULTTYPES

    constructor(public payload: companyFaultRequests[]) { }
}

export class ClearCompanyFaultTypes implements Action {
    readonly type = CLEAR_COMPANYFAULTTYPES
    constructor() { }
}

export class RemoveCompanyFaultTypes implements Action {
    readonly type = REMOVE_COMPANYFAULTTYPES

    constructor(public payload: number) { }
}

export type Actions = AddCompanyFaultTypes | UpdateCompanyFaultTypes | AddListCompanyFaultTypes | ClearCompanyFaultTypes | RemoveCompanyFaultTypes