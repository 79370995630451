import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { AppState } from "src/@core/ngrx/app.state";
import { companyFaultRequests } from "../models/companyFaultRequests";
import { ErrorHandleService } from "./_base_services/error-Handle.service";
import { StateMainService } from "./_base_services/state-main.service";
import * as CompanyFaultRequestsStateActions from './../../@core/ngrx/actions/companyFaultRequests.actions';

@Injectable()
export class CompanyFaultRequestStateService extends StateMainService{

  constructor(http: HttpClient, errorHandleService: ErrorHandleService, private store: Store<AppState>, notification: NzNotificationService) {
    super(http, errorHandleService, notification);
  }
  
  getAllCompanyFaultRequestsState() {
    return new Promise<Array<any>>((resolve) => {
      this.Get('/CompanyFaultRequests/getall').subscribe((resp: any) => {
        resolve(resp.data)
      }, error => {
        this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ');
      })
    })
  }


  getCompanyFaultRequestsState(refreshAll?: Boolean) {
    // state kontrol et => dizi boşsa get at listeyi doldur
    // todo store kontrol
    let List: companyFaultRequests[] = [];
    if (refreshAll) {
      this.store.dispatch(new CompanyFaultRequestsStateActions.ClearCompanyFaultRequests());
    }
    this.store.select('companyFaultRequests').subscribe(async (resp: companyFaultRequests[]) => {
      if (resp.length == 0) {
        const companyFaultReducerList = await this.getAllCompanyFaultRequestsState();
        this.store.dispatch(new CompanyFaultRequestsStateActions.AddListCompanyFaultRequests(companyFaultReducerList));
      }
    });
  }

  addSingleProduct_State(obj) {
    this.store.dispatch(new CompanyFaultRequestsStateActions.AddCompanyFaultRequests(obj))
  }

  deleteSingleProduct_State(id) {
    this.store.dispatch(new CompanyFaultRequestsStateActions.RemoveCompanyFaultRequests(id))
  }
  updateSingleProduct_State(obj) {
     this.store.dispatch(new CompanyFaultRequestsStateActions.UpdateCompanyFaultRequests(obj))
  }


  
  createObj(obj) {
    return new Promise<boolean>((resolve: any) => {
      this.Create('/CompanyFaultRequests', obj).subscribe((resp: any) => {
        this.addSingleProduct_State(resp.data);
        this.notification.create('success', `${obj.name} eklendi`, '')
      }, error => {
        this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
      })
      resolve(false)
    })
  }


  updateObj(obj) {
    return new Promise<boolean>((resolve: any) => {
      this.Update('/CompanyFaultRequests', obj).subscribe((resp: any) => {
        this.notification.create('success', `${obj.name} güncellendi`, '')
        this.updateSingleProduct_State(obj);
      },error => {
          this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
        })
        resolve(false)
    })
  }

  deleteObj(obj) {
    this.Remove('/CompanyFaultRequests', obj.id).subscribe((resp: any) => {
      this.deleteSingleProduct_State(obj.id)
      this.notification.create('success', `${obj?.name} Silindi`, '')
    }, error => {
      this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
    })
  }

}
