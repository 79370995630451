import { Injectable } from '@angular/core';
import { MainService } from './_base_services/main.service';
import { ErrorHandleService } from './_base_services/error-Handle.service';
import { HttpClient } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Store } from '@ngrx/store';
import { AppState } from 'src/@core/ngrx/app.state';
import { Customer } from '../models/customer';
import * as CustomerActions from './../../@core/ngrx/actions/customers.action';

declare var $:any;

@Injectable()
export class GlobalService extends MainService {

  constructor(http: HttpClient, errorHandleService: ErrorHandleService,private store: Store<AppState> , private notification: NzNotificationService) {
    super(http, errorHandleService);
  }

  clearStorage() {
    sessionStorage.removeItem('supplierAuth');
    sessionStorage.removeItem('expiration');
    sessionStorage.removeItem('role')
    localStorage.removeItem('role')
    localStorage.removeItem('supplierAuth');
    localStorage.removeItem('expiration');
  }

  formValidationMessage(invalidItems) {
    let fControls = invalidItems;
    for (const key in fControls) {
      if (Object.prototype.hasOwnProperty.call(fControls, key)) {
        const element = fControls[key];
        if (element.status == 'INVALID') {
          this.notification.create('error', `${key} boş bırakılamaz`, null, { nzKey: `${key}` });
        }
      }
    }
  }

  createNotification(type, title, content) {
    this.notification.create(type, title, content);
  }

  getCountries(){
    return new Promise<Array<any>>((resolve)=>{
      this.Get('/countries').subscribe((resp:any)=>{
        console.log('resp' , resp)
        resolve(resp.data)
      })
    })
  }



     // todo CUSTOMERS
     
     getAllCustomers(){
      return new Promise<Array<any>>((resolve)=>{
        this.Get('/Customers/getall').subscribe((resp:any)=>{
          console.log('Global Service Fault Type İstek ' , resp)
          resolve(resp.data)
        })
      })
    } 
  
    getCustomersState(refreshAll?:Boolean){
      //alert('--OK--')
      // state kontrol et => dizi boşsa get at listeyi doldur
      // todo store kontrol
      if (refreshAll) {
        this.store.dispatch(new CustomerActions.ClearCUSTOMERS())
      }
      
      this.store.select('customers').subscribe(async (resp:Customer[])=>{
        if (resp.length == 0) {
         const customersList = await this.getAllCustomers();
         this.store.dispatch(new CustomerActions.AddListCUSTOMERS(customersList));
         console.log('resp???' , resp);
        }

      });
    }


}

