import { Action } from "@ngrx/store"
import { Customer } from "src/app/models/customer"


export const ADD_CUSTOMER       = '[CUSTOMER] Ekle'
export const ADD_LIST_CUSTOMERS  = '[CUSTOMERS] Liste Ekle'
export const CLEAR_CUSTOMERS    = '[CUSTOMER] List Temizle'
export const REMOVE_CUSTOMER    = '[CUSTOMER] Sil'


export class AddCUSTOMER implements Action {
    readonly type = ADD_CUSTOMER

    constructor(public payload: Customer) {}
}

export class AddListCUSTOMERS implements Action {
    readonly type = ADD_LIST_CUSTOMERS

    constructor(public payload: Customer[]) {}
}

export class RemoveCUSTOMERS implements Action {
    readonly type = REMOVE_CUSTOMER

    constructor(public payload: number) {}
}

export class ClearCUSTOMERS implements Action {
    readonly type = CLEAR_CUSTOMERS
    constructor() {}
}

export type Actions = AddCUSTOMER | AddListCUSTOMERS | RemoveCUSTOMERS | ClearCUSTOMERS