import { Customer } from 'src/app/models/customer';
import * as CustomerActions from '../actions/customers.action';


/* const initialState: CUSTOMERSTest[] = [
    {
        name: 'Gökhan',
        surname: 'Öztürk',
        gender: 'Male'
    },
    {
        name: 'gunes',
        surname: 'Öztürk',
        gender: 'Male'
    }

] */


export function CustomersReducer(state: Customer[] = [], action: CustomerActions.Actions) {
    switch (action.type) {
        case CustomerActions.ADD_CUSTOMER:
            return [...state, action.payload];

        case CustomerActions.ADD_LIST_CUSTOMERS:
            return [...state, ...action.payload];

            case CustomerActions.CLEAR_CUSTOMERS:
                return [];
            
            
        case CustomerActions.REMOVE_CUSTOMER :
            state.splice(action.payload, 1)
            return state;
        default:
            return state;
    }
}