import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { AppState } from "src/@core/ngrx/app.state";
import { environment } from "src/environments/environment";
import { companyFaultTypes } from "../models/companyFaultTypes";
import { ErrorHandleService } from "./_base_services/error-Handle.service";
import { StateMainService } from "./_base_services/state-main.service";
import * as CompanyFaultTypeStateActions from './../../@core/ngrx/actions/companyFaultTypes.actions';
@Injectable()
export class CompanyFaultTypesService extends StateMainService{

  constructor(http: HttpClient, errorHandleService: ErrorHandleService, private store: Store<AppState>, notification: NzNotificationService) {
    super(http, errorHandleService, notification);
  }
  
  getAllCompanyFaultTypeState() {
    return new Promise<Array<any>>((resolve) => {
      this.Get('/CompanyFaultTypes/getall').subscribe((resp: any) => {
        resolve(resp.data)
      }, error => {
        this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ');
      })
    })
  }


  getCompanyFaultTypeState(refreshAll?: Boolean) {
    // state kontrol et => dizi boşsa get at listeyi doldur
    // todo store kontrol
    let List: companyFaultTypes[] = [];
    if (refreshAll) {
      this.store.dispatch(new CompanyFaultTypeStateActions.ClearCompanyFaultTypes());
    }
    this.store.select('companyFaultTypes').subscribe(async (resp: companyFaultTypes[]) => {
      if (resp.length == 0) {
        const companyFaultTypeList= await this.getAllCompanyFaultTypeState();
        this.store.dispatch(new CompanyFaultTypeStateActions.AddListCompanyFaultTypes (companyFaultTypeList));
      }
    });
  }

  addSingleProduct_State(obj) {
    this.store.dispatch(new CompanyFaultTypeStateActions.AddCompanyFaultTypes(obj))
  }

  deleteSingleProduct_State(id) {
    this.store.dispatch(new CompanyFaultTypeStateActions.RemoveCompanyFaultTypes (id))
  }
  updateSingleProduct_State(obj) {
     this.store.dispatch(new CompanyFaultTypeStateActions.UpdateCompanyFaultTypes(obj))
  }


  
  createObj(obj) {
    return new Promise<boolean>((resolve: any) => {
      this.Create('/companyFaultTypes', obj).subscribe((resp: any) => {
        this.addSingleProduct_State(resp.data);
        this.notification.create('success', `${obj.name} eklendi`, '')
      }, error => {
        this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
      })
      resolve(false)
    })
  }


  updateObj(obj) {
    return new Promise<boolean>((resolve: any) => {
      this.Update('/ccompanyFaultTypes', obj).subscribe((resp: any) => {
        this.notification.create('success', `${obj.name} güncellendi`, '')
        this.updateSingleProduct_State(obj);
      },error => {
          this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
        })
        resolve(false)
    })
  }

  deleteObj(obj) {
    this.Remove('/cars', obj.id).subscribe((resp: any) => {
      this.deleteSingleProduct_State(obj.id)
      this.notification.create('success', `${obj?.name} Silindi`, '')
    }, error => {
      this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
    })
  }

}