<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar menu-shadow z-sider" nzTheme="light" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" nzCollapsible nzWidth="256px" nzBreakpoint="md">
        <div class="capsa">
            <div class="sidebar-logo">
                <a href="/">
                    <img src="./../../../assets/app-assets/images/svg/matsis-logo-black.svg" alt="logo">
                    <!--   <h1 class="brand-text">Matsis iş takip</h1> -->
                </a>
            </div>
            <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsedDash" nzMenuClassName="gj">

                <!-- todo Hoşgeldiniz -->
                <li nz-menu-item nzMatchRouter #test nzTitle="test" nzIcon="settings">
                    <span class="ficon mr-1">o</span>
                    <a routerLink="/Hosgeldiniz">Hoş Geldiniz</a>
                </li>


                <!-- TODO Side NavItems Role based Menu -->
                <ng-container *ngFor="let item of sideNavRoleFiltered">

                    <li *ngIf="item.type == 'seperator'" class="navigation-header">
                        <span>{{item?.title}}</span>
                    </li>

                    <li *ngIf="item.type == 'menu-item'" nz-menu-item nzMatchRouter nzTitle="{{item?.title}}" nzIcon="settings">
                        <span class="ficon mr-1"> <i nz-icon class="ficon  tr-3 mr-1" nzType="appstore-add"  nzTheme="outline"></i></span>
                        <a routerLink="{{item?.url}}">{{item?.title}}</a>
                    </li>

                    <li *ngIf="item.type == 'sub-menu'" nz-submenu [nzOpen]="sideNavItems.isSelected" #sideNavItems nzTitle="{{item?.title}}" nzIcon="tag">
                        <ul>
                            <li nz-menu-item nzMatchRouter *ngFor="let subItem of item?.menuItems">
                                <span class="ficon">o</span>
                                <a routerLink="{{subItem?.url}}">{{subItem?.title}}</a>
                            </li>
                        </ul>
                    </li>
                </ng-container>





            </ul>
        </div>
    </nz-sider>
    <div class="zifix"></div>
    <nz-layout class="right-layout" [ngClass]="{'slideMargin': isCollapsed}" [ngClass]="{'slideMargin': isCollapsed}">
        <nz-content>
            <div class="inner-content">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
    </nz-layout>



</nz-layout>

<nav [ngClass]="{'collapsed-header': isCollapsed}" class="header-navbar navbar  navbar-expand-lg z-in9999 fixed-gkhn align-items-center pos-r  floating-nav navbar-light navbar-shadow">
    <div class="navbar-container z-in9999  d-flex content justify-content-between">
        <div class="bookmark-wrapper d-flex align-items-center">
            <ul class="nav navbar-nav d-xl-none">
                <li class="nav-item">
                    <a class="nav-link menu-toggle">
                        <i class="ficon" data-feather="menu"></i></a>
                </li>
            </ul>
            <ul class="nav navbar-nav">
                <li> <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
          </span></li>
            </ul>
        </div>



        <!-- todo User DropDown -->
        <a nz-dropdown [nzDropdownMenu]="menu">
            <ul class="nav navbar-nav align-items-center ml-auto">
                <li class="nav-item dropdown dropdown-user">
                    <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="user-nav d-sm-flex d-none">
                            <span class="user-name font-weight-bolder">{{userName}}
                                 </span><span class="user-status" *ngFor="let item of userRoles">
                                    <span *ngIf="item == 'admin'" >Yönetici</span> 
                                    <span *ngIf="item == 'manager'" >Saha Şefi</span> 
                                    <span *ngIf="item == 'personel'" >Personel</span> 
                                    <span *ngIf="item == 'engineer'" >Keşif Personeli</span> 
                                    <span *ngIf="item == 'accountant'" >Muhasebe</span> 
                                    </span>
                        </div><span class="avatar">
                            <nz-avatar nzIcon="user"></nz-avatar>
             <!--  <img class="round" src="assets/app-assets/images/portrait/small/avatar-s-11.jpg" alt="avatar" height="40"
                width="40"> --><span class="avatar-status-online"></span></span>
                    </a>
                </li>
            </ul>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
                <a class="dropdown-item" class="dropdown-item" routerLink="/hesap-ayarlari/genel-ayarlar">Hesabım</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/hesap-ayarlari/sifre-degistir">Şifre Değiştir</a>
                <a class="dropdown-item" class="dropdown-item" (click)="signOut()"> Çıkış Yap</a>
            </ul>
        </nz-dropdown-menu>

    </div>
</nav>