import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { AppState } from "src/@core/ngrx/app.state";
import { Cars } from "../models/cars";
import { ErrorHandleService } from "./_base_services/error-Handle.service";
import * as CarsStateActions from './../../@core/ngrx/actions/cars.actions';
import { StateMainService } from "./_base_services/state-main.service";

@Injectable()
export class CarStateService extends StateMainService{

  constructor(http: HttpClient, errorHandleService: ErrorHandleService, private store: Store<AppState>, notification: NzNotificationService) {
    super(http, errorHandleService, notification);
  }
  
  getAllCarsState() {
    return new Promise<Array<any>>((resolve) => {
      this.Get('/Cars/getall').subscribe((resp: any) => {
        resolve(resp.data)
      }, error => {
        this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ');
      })
    })
  }


  getCarsState(refreshAll?: Boolean) {
    // state kontrol et => dizi boşsa get at listeyi doldur
    // todo store kontrol
    let List: Cars[] = [];
    if (refreshAll) {
      this.store.dispatch(new CarsStateActions.ClearCars());
    }
    this.store.select('cars').subscribe(async (resp: Cars[]) => {
      if (resp.length == 0) {
        const carsList = await this.getAllCarsState();
        this.store.dispatch(new CarsStateActions.AddListCars(carsList));
      }
    });
  }

  addSingleProduct_State(obj) {
    this.store.dispatch(new CarsStateActions.AddCars(obj))
  }

  deleteSingleProduct_State(id) {
    this.store.dispatch(new CarsStateActions.RemoveCars (id))
  }
  updateSingleProduct_State(obj) {
     this.store.dispatch(new CarsStateActions.UpdateCars(obj))
  }


  
  createObj(obj) {
    return new Promise<boolean>((resolve: any) => {
      this.Create('/cars', obj).subscribe((resp: any) => {
        this.addSingleProduct_State(resp.data);
        this.notification.create('success', `${obj.name} eklendi`, '')
      }, error => {
        this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
      })
      resolve(false)
    })
  }


  updateObj(obj) {
    return new Promise<boolean>((resolve: any) => {
      this.Update('/cars', obj).subscribe((resp: any) => {
        this.notification.create('success', `${obj.name} güncellendi`, '')
        this.updateSingleProduct_State(obj);
      },error => {
          this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
        })
        resolve(false)
    })
  }

  deleteObj(obj) {
    this.Remove('/cars', obj.id).subscribe((resp: any) => {
      this.deleteSingleProduct_State(obj.id)
      this.notification.create('success', `${obj?.name} Silindi`, '')
    }, error => {
      this.notification.create('error', 'Hata Oluştu', 'Lütfen sayfayı yenileyip tekrar deneyin ')
    })
  }

}
