<section class="row">
    <div class="col-md-12">
        <div class="card text-center">
            <div class="card-header py-2" *ngIf="buttonText.length > 0">
                <ul class="nav nav-pills card-header-pills ml-0" id="pills-tab">
                    <li class="nav-item">
                        <a class="nav-link active">{{buttonText}} </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home">
                        <h2>{{heading}}</h2>
                        <p class="card-text">{{subHeading}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>