// auth.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from '../user.service';
import { error } from 'protractor';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserRolesService } from './user-roles.service';
import { isArray } from 'node:util';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private user: UserService, private router: Router,private userRolesService:UserRolesService) { }

  async canActivate() {
   // this.router.navigate(['/login']);
    // 1 token varmı kontrol et

    if (!this.user.isLoggedIn()) {
     // this.router.navigate(['/uye/giris']);
     this.router.navigate(['/uye/giris']);
      return false;
    }

    // tokenı apiden doğrula
     const checkToken = await this.verifyToken();
   // alert('verify' + checkToken)
    if(checkToken){
        return true;
      } 
    return true;
  }

  verifyToken(){
    return new Promise((resolve)=>{
     // alert('verify ici')
      const myRawToken = localStorage.getItem('token')
     // console.log('token auth guard', )
      const jwtHelper = new JwtHelperService();
      const decodedToken = jwtHelper.decodeToken(myRawToken);
      const isRolesArray = Array.isArray(decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
      console.log('isRoleArray' , isRolesArray)
      let roles:any[] = []; // role guard servis rolleri dizi istiyor
      if (!isRolesArray) { roles.push(decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])  }
      if (isRolesArray) { roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']; }

     /*  console.log('--' , decodedToken)
      console.log('roles hataa' ,roles)
      console.log('is Array' ) */

      const isExpired = jwtHelper.isTokenExpired(myRawToken);

      
     // console.log('isExpired' , isExpired)
     // console.log('decodedTokenAuthGuard' , decodedToken)
     // console.log('role AuthGuard' , decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])


      
      const verify = roles.some(x=> x == 'admin' || x == 'manager' || x == 'accountant');
    //  alert(roles)
    //  console.log('verify' , verify) 
      if (!verify || isExpired) {
        this.clearStorage();
        resolve(false);
      }
      this.userRolesService.setRoles(roles); // user servise rolleri yukle
      resolve(verify)
    })
  }
  

/*   verifyToken(){
    return new Promise((resolve)=>{
      // token doğrulama işlemi yap
      // local storageteki tokenı yada user ı kullanarak apiye ulaş
      // true yada false donen cevaba gore hareket et
      this.user.Get('/UserAction/TokenVerify').subscribe((resp)=>{
        // gelen cevap true token geçerli 
        // false clearStorage  ve logine git

       
       if (resp == false) {
         this.clearStorage();
         resolve(false);
       } 
        
        resolve(true)
      
      }, (error:any)=>{
        this.clearStorage();
        return false;
      })
   
    })
  } */

  clearStorage():void{
    sessionStorage.removeItem('auth');
    sessionStorage.removeItem('expiration');
    sessionStorage.removeItem('role');
    localStorage.removeItem('auth');
    localStorage.removeItem('expiration');
    localStorage.removeItem('role');
    this.router.navigate(['/uye/giris']);
  }

}
