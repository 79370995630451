import { Action } from "@ngrx/store"
import { Cars } from "src/app/models/cars"

export const ADD_CARS       = '[CAR] Ekle'
export const UPDATE_CARS    = '[CAR] Güncelle'
export const ADD_LIST_CARS  = '[CAR] Liste Ekle'
export const CLEAR_CARS     = '[CAR] List Temizle'
export const REMOVE_CARS    = '[CAR] Sil'


export class AddCars implements Action {
    readonly type = ADD_CARS

    constructor(public payload: Cars) {}
}

export class UpdateCars implements Action {
    readonly type = UPDATE_CARS

    constructor(public payload: Cars) {}
}

export class AddListCars implements Action {
    readonly type = ADD_LIST_CARS

    constructor(public payload: Cars[]) {}
}

export class ClearCars implements Action {
    readonly type = CLEAR_CARS
    constructor() { }
}

export class RemoveCars implements Action {
    readonly type = REMOVE_CARS

    constructor(public payload: number) {}
}

export type Actions = AddCars | UpdateCars |  AddListCars |  ClearCars | RemoveCars