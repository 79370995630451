import { companyFaultRequests } from 'src/app/models/companyFaultRequests';
import * as companyFaultRequestsActions from '../actions/companyFaultRequests.actions';

export function CompanyFaultRequestsReducer(state: companyFaultRequests[] = [], action: companyFaultRequestsActions.Actions) {
    switch (action.type) {
        case companyFaultRequestsActions.ADD_COMPANYFAULTREQUESTS:
         
            let sortedList = [...state, action.payload];
            return sortedList.slice().sort((a: companyFaultRequests, b: companyFaultRequests) => {
                return b.id - a.id;
            })

        case companyFaultRequestsActions.UPDATE_COMPANYFAULTREQUESTS:
            let newArray = [...state];
            const index = newArray.findIndex(x => x.id == action.payload.id);
            newArray[index] = action.payload;
            return [...newArray ];

        case companyFaultRequestsActions.ADD_LIST_COMPANYFAULTREQUESTS:
            let newStatelist = [];
            return [...newStatelist, ...action.payload];

        case companyFaultRequestsActions.CLEAR_COMPANYFAULTREQUESTS:
            let newStatelistClear = [];
            return newStatelistClear;

        case companyFaultRequestsActions.REMOVE_COMPANYFAULTREQUESTS:
            var ind: number = state.findIndex(x => x.id == action.payload)
            let newState = [...state];
            newState.splice(ind, 1);
            return newState;

        default:
            return state;
    }
}