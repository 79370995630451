<div class="content-header-left col-md-9 col-12 mb-2">
    <div class="row breadcrumbs-top">
        <div class="col-12">
            <h2 class="content-header-title float-left mb-0">{{bigRoute}}</h2>
            <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/Hosgeldiniz">Anasayfa</a>
                    </li>
                    <li class="breadcrumb-item active"><a onclick="window.location.reload()">{{smallRoute}}</a>
                </ol>
            </div>
        </div>
    </div>
</div>