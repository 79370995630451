import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() bigRoute:string='';
  @Input() smallRoute:string = '';
  location = window.location.pathname;
  constructor() { 
    
  }

  ngOnInit(): void {
  }

}
