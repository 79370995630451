

import { companyFaultTypes } from 'src/app/models/companyFaultTypes';
import * as CompanyFaultTypesActions from '../actions/companyFaultTypes.actions';

export function CompanyFaultTypesReducer(state: companyFaultTypes[] = [], action: CompanyFaultTypesActions.Actions) {
    switch (action.type) {
        case CompanyFaultTypesActions.ADD_COMPANYFAULTTYPES:

            let sortedList = [...state, action.payload];
            return sortedList.slice().sort((a: companyFaultTypes, b: companyFaultTypes) => {
                return b.id - a.id;
            })

        case CompanyFaultTypesActions.UPDATE_COMPANYFAULTTYPES:
            let newArray = [...state];
            const index = newArray.findIndex(x => x.id == action.payload.id);
            newArray[index] = action.payload;
            return [...newArray];

        case CompanyFaultTypesActions.ADD_LIST_COMPANYFAULTTYPES:
            let newStatelist = [];
            return [...newStatelist, ...action.payload];

        case CompanyFaultTypesActions.CLEAR_COMPANYFAULTTYPES:
            let newStatelistClear = [];
            return newStatelistClear;

        case CompanyFaultTypesActions.REMOVE_COMPANYFAULTTYPES:
            var ind: number = state.findIndex(x => x.id == action.payload)
            let newState = [...state];
            newState.splice(ind, 1);
            return newState;

        default:
            return state;
    }
}