export const sideNavItemsAll = [
  {
    title: 'İŞ AKIŞI',
    url: null,
    type: 'seperator',
    roles: ['admin','manager' , 'accountant'],
  },
  {
    title: 'İş Emri oluştur',
    url: '/is-emri/yeni',
    type: 'menu-item',
    roles: ['admin','manager'],
  },
  {
    title: 'İş Emirleri',
    url: '/is-emri/liste',
    type: 'menu-item',
    roles: ['admin','manager','accountant'],
  },
  {
    title: 'Arızalar',
    url: '/hasar-islem/liste',
    type: 'menu-item',
    roles: ['admin','manager','engineer'],
  },
  {
    title: 'Raporlar',
    url: '/raporlar',
    type: 'menu-item',
    roles: ['admin','manager','accountant'],
  },
  {
    title: 'TANIMLAMALAR',
    url: null,
    type: 'seperator',
    roles: ['admin','manager' ],
  },
  {
    title: 'Müşteriler',
    url: '/musteri/liste',
    type: 'menu-item',
    roles: ['admin','manager','accountant'],
  },
  {
    title: 'Personeller',
    url: '/personel/liste',
    type: 'menu-item',
    roles: ['admin','manager','accountant'],
  },
  {
    title: 'Araçlar',
    url: '/arac/liste',
    type: 'menu-item',
    roles: ['admin', 'manager','accountant'],
  },
  {
    title: 'Arıza Tanımlamaları',
    url: 'is-tanim',
    type: 'sub-menu',
    roles: ['admin', 'manager'],
    menuItems: [
      {
        title: 'Arıza Türleri',
        url: '/is-tanim/ariza-turleri',
      },
      {
        title: 'Arıza Talepleri',
        url: '/is-tanim/ariza-talepleri',
      },
      {
        title: 'Hasar Tanımlamaları',
        url: '/is-tanim/hasar-tanim',
      } 
    ]
  },
  {
    title: 'Bildirim Sistemi',
    url: 'bildirimler',
    type: 'sub-menu',
    roles: ['admin', 'manager'],
    menuItems: [
      {
        title: 'Grupları Düzenle',
        url: '/bildirimler/duzenle',
      },
      {
        title: 'Bildirim Gönder',
        url: '/bildirimler/gonder',
      }
    ]
  },
  {
    title: 'Ayarlar',
    url: 'ayarlar',
    type: 'sub-menu',
    roles: ['admin'],
    menuItems: [
      {
        title: 'Rol - Yetki',
        url: '/ayarlar/rol-yetki',
      },
      {
        title: 'İş Durumları',
        url: '/ayarlar/is-durumlari',
      }
    ]
  },
/*   {
    title: 'Muha',
    url: 'ayarlar',
    type: 'menu-item',
    roles: ['accountant'],
    menuItems: [
      {
        title: 'test1 - muhasebe',
        url: '/ayarlar/muha',
      },
      {
        title: 'test2 - muhasebe',
        url: '/ayarlar/muha2',
      }
    ]
  } */
]
