import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/_base_services/auth.guard';
import { RoleGuardService } from './services/_base_services/role-guard.service';
import { DefaultLayoutComponent } from './_default_layout';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/is-emri/liste' },
  {
    path: '', component: DefaultLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'Hosgeldiniz', loadChildren: () => import('./_pages/welcome/welcome.module').then(m => m.WelcomeModule) },
      { path: 'is-emri', loadChildren: () => import('./_pages/work-order/work-order.module').then(m => m.WorkOrderModule), canActivate: [RoleGuardService], data: { roles: ['admin', 'manager','accountant'] } },
      { path: 'hasar-islem', loadChildren: () => import('./_pages/company-faults/company-faults.module').then(m => m.CompanyFaultsModule), canActivate: [RoleGuardService], data: { roles: ['admin', 'manager','accountant'] } },
      { path: 'musteri', loadChildren: () => import('./_pages/customer/customer.module').then(m => m.CustomerModule), canActivate: [RoleGuardService], data: { roles: ['admin', 'manager', 'accountant'] } },
      { path: 'personel', loadChildren: () => import('./_pages/personal/personal.module').then(m => m.PersonalModule), canActivate: [RoleGuardService], data: { roles: ['admin', 'manager', 'accountant'] } },
      { path: 'arac', loadChildren: () => import('./_pages/vehicle/vehicle.module').then(m => m.VehicleModule), canActivate: [RoleGuardService], data: { roles: ['admin', 'manager', 'accountant'] } },
      { path: 'ekipman', loadChildren: () => import('./_pages/equipment/equipment.module').then(m => m.EquipmentModule), canActivate: [RoleGuardService], data: { roles: ['admin', 'manager', 'accountant'] } },
      { path: 'is-tanim', loadChildren: () => import('./_pages/work-definitions/work-definitions.module').then(m => m.WorkDefinitionsModule), canActivate: [RoleGuardService], data: { roles: ['admin', 'manager'] } },
      { path: 'ayarlar', loadChildren: () => import('./_pages/settings/settings.module').then(m => m.SettingsModule), canActivate: [RoleGuardService], data: { roles: ['admin'] } },
      { path: 'raporlar', loadChildren: () => import('./_pages/reports/reports.module').then(m => m.ReportsModule), canActivate: [RoleGuardService], data: { roles: ['admin','manager', 'accountant'] } },
      { path: 'bildirimler', loadChildren: () => import('./_pages/notification/notification.module').then(m => m.NotificationModule), canActivate: [RoleGuardService], data: { roles: ['admin', 'manager'] } },
    ]
  },
  { path: 'uye', loadChildren: () => import('./u-auth/u-auth.module').then(m => m.UAuthModule) },
  { path: '**', loadChildren: () => import('./_pages/notfound/notfound.module').then(m => m.NotfoundModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
