import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class PageheaderComponent implements OnInit {

  @Input() buttonText:string ='';
  @Input() heading:string ='';
  @Input() subHeading:string ='';

  constructor() { }

  ngOnInit(): void {
  }

}
