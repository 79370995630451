import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzInputModule } from 'ng-zorro-antd/input';
import { MustMatchDirective } from 'src/app/directives/must-match.directive';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { PageHeaderModule } from '../page-header/page-header.module';


@NgModule({
  declarations: [MustMatchDirective],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NzIconModule,
    NzInputModule,
    NzButtonModule,
    BreadcrumbModule,
    PageHeaderModule
  ],
  exports: [
    FormsModule,
    RouterModule,
    NzInputModule,
    MustMatchDirective,
    NzButtonModule,
    NzIconModule,
    BreadcrumbModule,
    PageHeaderModule

  ]
})
export class SharedModule { }
