import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetails } from 'src/app/models/_auth/userDetails';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserRolesService } from 'src/app/services/_base_services/user-roles.service';
import { sideNavItemsAll } from './sideNavMenuItems';


@Component({
  selector: 'app-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  isCollapsed = true;
  isCollapsedDash = true;
  isCollapsedOtherMenu = true;

  @ViewChildren('sideNavItems') sideNavItems: QueryList<ElementRef>;

  userName :any; //= new UserDetails();
  userRoles: any[] = [];

  sideNavList:any[] = sideNavItemsAll;

  constructor(private Router: Router, private service: GlobalService, private userService: UserService, private userRolesService: UserRolesService) {
    this.userRoles = this.userRolesService.getRoles();
    this.userName = this.userService.getUserDetails()['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
  }

  sideNavRoleFiltered: any[] = [];

  ngOnInit() {
    this.userRoles.forEach(role => {
      const _tmp: any[] = this.sideNavList.filter(x => x.roles.toString().includes(role));
      this.sideNavRoleFiltered = [...new Set([...this.sideNavRoleFiltered, ..._tmp])];

      this.service.getCustomersState();
    });

    

  }

  signOut() {
    localStorage.removeItem("token");
    this.Router.navigate(['/uye/giris']);
  }


}
