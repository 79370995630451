import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { tr_TR } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import tr from '@angular/common/locales/tr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { DefaultLayoutComponent } from './_default_layout';
import { GlobalService } from './services/global.service';
import { UserService } from './services/user.service';
import { AppGlobalErrorHandler } from './services/_base_services/app-global-errorHandler';
import { AuthGuard } from './services/_base_services/auth.guard';
import { OnlyAdmins } from './services/_base_services/auth.only-admins';
import { OnlyCashier } from './services/_base_services/auth.only-cashier';
import { ErrorHandleService } from './services/_base_services/error-Handle.service';
import { MainService } from './services/_base_services/main.service';
import { SharedModule } from './modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './services/_base_services/AuthInterceptor';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { RoleGuardService } from './services/_base_services/role-guard.service';
import { UserRolesService } from './services/_base_services/user-roles.service';
import { WorkOrderService } from './services/work-order.service';


import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { countryReducer } from 'src/@core/ngrx/reducers/country.reducer';
import { gkhnReducer } from 'src/@core/ngrx/reducers/gkhn.reducer';
import { statusReducer } from 'src/@core/ngrx/reducers/status.reducer';
import { workOrderReducer } from 'src/@core/ngrx/reducers/workorder.reducer';
import { faultTypeReducer, faultRequestReducer } from 'src/@core/ngrx/reducers/workOrderExt.reducer';
import { CustomersReducer } from 'src/@core/ngrx/reducers/customers.reducer';
import { CarsReducer } from 'src/@core/ngrx/reducers/cars.reducer';
import { CompanyFaultTypesReducer } from 'src/@core/ngrx/reducers/companyFaultTypes.reducer';
import { CarStateService } from './services/carState.service';
import { CompanyFaultService } from './services/company-fault.service';
import { CompanyFaultRequestStateService } from './services/companyFaultRequestsState.service';
import { CompanyFaultTypesService } from './services/companyFaultTypesState.service';
import { CompanyFaultRequestsReducer } from 'src/@core/ngrx/reducers/companyFaultRequests.reducer';

registerLocaleData(tr);
const _default_layout = [
  DefaultLayoutComponent
];


@NgModule({
  declarations: [
    AppComponent,
    ..._default_layout,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    SharedModule,
    FormsModule,
    NzNotificationModule,
    NzAffixModule,
    NzDropDownModule,
    NzAvatarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    StoreModule.forRoot({
      gkhn: gkhnReducer, 
      workOrders: workOrderReducer,
      country: countryReducer,
      faultType: faultTypeReducer,
      faultRequest: faultRequestReducer,
      statuses : statusReducer,
      customers : CustomersReducer,
      cars : CarsReducer,
      companyFaultRequests: CompanyFaultRequestsReducer ,
      companyFaultTypes:CompanyFaultTypesReducer,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  providers: [
    MainService,
    GlobalService,
    UserService,
    ErrorHandleService,
    WorkOrderService,
    CarStateService,
    CompanyFaultRequestStateService,
    CompanyFaultTypesService,
    AuthGuard,
    OnlyAdmins,
    OnlyCashier,
    UserRolesService,
    RoleGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppGlobalErrorHandler },
    { provide: NZ_I18N, useValue: tr_TR }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
