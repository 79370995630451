import { AfterViewInit, Component } from '@angular/core';
import { GlobalService } from './services/global.service';
import { UserService } from './services/user.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import {  CarStateService } from './services/carState.service';
import { CompanyFaultService } from './services/company-fault.service';
import { CompanyFaultRequestStateService } from './services/companyFaultRequestsState.service';
import { CompanyFaultTypesService } from './services/companyFaultTypesState.service';

declare var $: any;

@Component({
  selector: 'body',
  template: '<router-outlet ></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {


  usersRef: any;
  udata: any;
  fcm: any;
  FcmUsers: any;
  FcmUsersId: any;
  FcmUsersData: any;
  isOneTime = false;
  isOneTime2 = false;

  constructor(private service: GlobalService, private db: AngularFirestore, private userService: UserService, private angularFireMessaging: AngularFireMessaging,
    private carService:CarStateService , private companyFaultTypeService:CompanyFaultTypesService,
    private companyFaultReqService:CompanyFaultRequestStateService,
    ) {
      carService.getCarsState(true);
      companyFaultReqService.getCompanyFaultRequestsState(true);
      companyFaultTypeService.getCompanyFaultTypeState(true);
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.fcm = token;
        console.log(token)
        this.FcmUsers = this.db.collection('users', ref => ref.where("fcm", "array-contains", token));

        this.FcmUsers.snapshotChanges().subscribe((resp) => {
          this.FcmUsersId = resp.map(c => c.payload.doc.id);
          this.FcmUsersData = resp.map(c => c.payload.doc.data().fcm);

        })

        setTimeout(() => {
          this.FcmUsersId.forEach((element, index) => {
            let i = this.FcmUsersData[index].indexOf(token);
            this.FcmUsersData[index].splice(i, 1)

            this.usersRef.doc(element + '').update({ fcm: this.FcmUsersData[index] })

          });
        }, 500);


        setTimeout(() => {
          this.udata.snapshotChanges().subscribe((resp) => {

            if (!this.isOneTime2) {
              this.isOneTime2 = true;

              let npa = [resp[0].payload.doc.data().fcm][0];
              if (npa.indexOf(this.fcm) > -1) {
                console.log("Var");

              } else {
                npa.push(this.fcm);
                this.usersRef.doc(resp[0].payload.doc.id + '').update({ fcm: npa });

              }

            }
          }
          )
        }, 1000);
      },
      (err) => {
        this.fcm = err;
      }
    );


  }


  ngAfterViewInit() {
    this.usersRef = this.db.collection('users');
    this.udata = this.db.collection('users', ref => ref.where("appId", "==", this.userService.getUserId()));
    this.requestPermission();
  }

}
